import productMenu from '@/productlayout/components/heard.vue';
<template>
  <div ref="maina">
    <div ref="main" class="main" style="min-height: 80vh;background-color: #eaedf1;">
      <div class="breadmenu" @click="goBack()">
        <i class="el-icon-arrow-left" /> 返回上一页
      </div>
      <div class="ssss">
        <div class="content">
          <div style="display: flex;">
            <!-- 左内容 -->
            <div class="image-s">
              <img class="image-i" :src="productObj.mainImage" alt="" srcset="">
            </div>
            <!-- 右内容 -->
            <div class="content-right">
              <div class="title">
                {{ productObj.subject }}
              </div>
              <div class="price">
                参考价格: ¥ {{ productPrice }}
              </div>
              <div class="time">
                上架时间： 180日以上
              </div>

              <div class="detail-btn-group">
                <a-button type="primary" class="details-btn" :class="collect?'collect':''" @click="addCollect()">
                  {{ collect?'已收藏':'收藏' }}
                </a-button>
                <a-button type="primary" class="details-btn" :class="warehouse?'collect':''" @click="openDialogShopVisible()">
                  {{ warehouse?'已上架':'上架' }}
                </a-button>
                <a-button type="primary" class="details-btn" @click="toBuyOrder()">
                  购买
                </a-button>
              </div>
              <div class="line" />
              <div class="tags-info">
                <div class="tags-info-detail">
                  销售指数
                  <div class="tags-info-font">
                    {{ randomNum(1, 100) }}
                  </div>
                </div>
                <div class="tags-info-detail">
                  评价数
                  <div class="tags-info-font">
                    {{ randomNum(1,100) }}
                  </div>
                </div>
                <div class="tags-info-detail">
                  评价得分
                  <div class="tags-info-font">
                    5566
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-detail">
            <div class="product-detail-title">
              商品详情
            </div>
            <div class="product-detail-content">
              <div v-html="productObj.description" />
            </div>
          </div>
        </div>
      </div>

      <!-- 推荐商品 -->
      <div class="ssss">
        <div class="content" style="margin-top:0px">
          <div class="recommend">
            同款/相似款货源推荐
          </div>
          <div v-for="(item, index) in recommendList" :key="index" class="recommend-list">
            <!-- <a-checkbox class="recommend-list-check" @change="onChange" /> -->
            <div class="recommend-list-images">
              <img class="recommend-list-imagei" :src="item.image" alt="" srcset="">
            </div>
            <div class="recommend-list-product">
              <div class="recommend-list-product-label">
                {{ item.subject }}
              </div>
              <div class="recommend-list-product-btn">
                <img src="https://ecom-catcher.oss-ap-southeast-1.aliyuncs.com/catcherprod/20201217/d3c9afd78ca245189745734583674b17.png" style="width:16px;height:16px;border-radius: 6px;" alt="" srcset="">
                <div class="recommend-list-product-btn-shop">
                  店铺
                </div>
                <a-button type="link" @click="seeDetails(item)">
                  查看原链接
                </a-button>
              </div>
              <div class="recommend-list-product-order">
                <div class="recommend-list-product-order-detail" style="flex:1 1">
                  <div class="recommend-list-product-order-detail-label">
                    采购价(不含运费)
                  </div>
                  <div class="recommend-list-product-order-detail-value">
                    ¥{{ item.wholesalePrice }}
                  </div>
                </div>
                <!-- <div class="recommend-list-product-order-detail">
                  <div class="recommend-list-product-order-detail-label">
                    订单数
                  </div>
                  <div class="recommend-list-product-order-detail-value">
                    xxx
                  </div>
                </div>
                <div class="recommend-list-product-order-detail">
                  <div class="recommend-list-product-order-detail-label">
                    采集次数
                  </div>
                  <div class="recommend-list-product-order-detail-value">
                    xxx
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 相似商品 -->
      <div class="ssss">
        <div class="content" style="margin-top:0px">
          <div class="recommend">
            相似商品
          </div>
          <div class="similar">
            <a-row :gutter="[24,24]" type="flex">
              <a-col v-for="(item,index) in similarList" :key="index" :span="6" @click="seeDetails(item)">
                <div class="similarity">
                  <div class="similarity-images">
                    <img class="similarity-imagei" :src="item.image" alt="" srcset="">
                  </div>
                  <div class="similarity-price">
                    参考价格:  ¥{{ item.wholesalePrice }}
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <el-dialog
      style="text-align: left;"
      title="请选择门店"
      :visible.sync="dialogShopVisible"
      width="50%"
      :before-close="handleBeforeClose"
    >
      <a-radio-group v-model="shopId">
        <a-radio v-for="(sitem,sindex) in shopList" :key="sindex" :style="radioStyle" :value="sitem.id">
          {{ sitem.shopName }}
        </a-radio>
      </a-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShopVisible = false">取 消</el-button>
        <el-button type="primary" @click="putaway()">上架</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { randomNum } from '@/utils/utils'
import { getAlibabaProductDetail, getBrandProductDetailedInformation, getAlibabaProductList, getBrandProductList, addCollect, addWarehouse, shopList, deleteCollect } from '@/api/register'
export default {
  data() {
    return {
      productPrice: null,
      warehouse: false,
      collect: false,
      productType: '',
      similarList: [],
      recommendList: [{}, {}],
      productObj: {},

      // 请选择店铺
      dialogShopVisible: false,
      shopList: [],
      shopId: '',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      }
    }
  },
  watch: {
    '$route'(to, from) {
      this.$router.go(0)
    }
  },
  created() {
    this.productType = this.$route.query.selectType
    const productId = this.$route.query.productId
    this.productPrice = this.$route.query.product
    if (productId && this.productType) {
      this.getProduct(productId, this.productType)
      this.getSimilarProductList()
    } else {
      this.$message.error('参数错误,请重新选择商品')
    }
  },
  mounted() {
    this.$el.parentElement.scrollTop = 0
  },
  methods: {
    onChange() {},

    // 获取详情
    getProduct(productId, productType) {
      if (productType === '1') {
        getAlibabaProductDetail(productId).then(res => {
          this.productObj = res.data
          if (this.productObj.collectId) {
            this.collect = true
          }
        })
      } else {
        getBrandProductDetailedInformation(productId).then(res => {
          this.productObj = res.data
          if (this.productObj.collectId) {
            this.collect = true
          }
        })
      }
    },

    // 获取列表
    getSimilarProductList() {
      if (this.productType === '1') {
        getAlibabaProductList().then(res => {
          this.similarList = res.data.records.slice(0, 8)
          this.recommendList = res.data.records.slice(0, 4)
        })
      } else {
        getBrandProductList().then(res => {
          this.similarList = res.data.records.slice(0, 8)
          this.recommendList = res.data.records.slice(0, 4)
        })
      }
    },

    // 收藏
    addCollect() {
      if (!this.productObj.productId) {
        this.$message.error('缺少参数，请重新选择商品')
        return
      }

      if (this.collect) {
        deleteCollect(this.productObj.collectId).then(res => {
          if (res.code === 0) {
            this.collect = false
            this.$message.success(res.msg)
          }
        })
      } else {
        addCollect({ productId: this.productObj.productId, type: this.productType }).then(res => {
          if (res.code === 0) {
            this.collect = true
            this.productObj.collectId = res.data
            this.$message.success(res.msg)
          }
        })
      }
    },

    // 随机数
    randomNum(min, max) {
      return randomNum(min, max)
    },

    // 关闭之前
    handleBeforeClose(done) {
      this.shopId = ''
      done()
    },

    // 上架
    addWarehouse() {
      if (!this.productObj.productId) {
        this.$message.error('缺少参数，请重新选择商品')
        return
      }
      addWarehouse({ productId: this.productObj.productId, shopId: this.shopId, supplyType: this.productType }).then(res => {
        if (res.code === 0) {
          this.warehouse = true
          this.$message.success(res.msg)
          this.dialogShopVisible = false
        }
      })
    },
    toBuyOrder() {
      if (!this.productObj.productId) {
        this.$message.error('缺少参数，请重新选择商品')
        return
      }
      this.$router.push({ path: '/detailproductorder/index', query: { productId: this.productObj.productId, selectType: this.productType }})
    },
    openDialogShopVisible() {
      this.dialogShopVisible = true
      this.shopList = []
      shopList().then(res => {
        if (res.code === 0) {
          this.shopList = res.data
        }
      })
    },

    /**
     * handle上架
     */
    putaway() {
      if (!this.shopId) {
        this.$message.warning('未选择店铺')
        return
      }
      this.addWarehouse()
    },

    // 查看自己
    seeDetails(item) {
      this.$router.push({ path: '/detailproduct/index', query: { productId: item.productId, selectType: this.productType, product: item.wholesalePrice }})
    },

    /** 返回上一页 */
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .main{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .ssss{
    width: 100%;
    max-width: 1400px;
  }
  .ssss + .ssss {
    margin-top: -10px;
  }
  // 总布局，分上下
  .content{
    border-radius: 6px;
    margin: 50px 10% 30px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .image-s {
  background-color: #fff;
  width: 370px;
  height: 370px;
  position: relative;
  margin-right: 20px;
}
  .image-i {
    object-fit: contain;
    object-position: center;
    height: 100%;
    z-index: 100;
  }

  // 内容
  .content-right{
    width: 0;
    flex: 1;
    text-align: left;
  }
  .title{
    vertical-align: middle;
    color: #242833;
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .price{
    display: flex;
    align-items: center;
    color: #363d4d;
    font-weight: 600;
    font-size: 16px;
    padding: 27px 0;
  }
  .time{
    text-align: left;
  }

  .detail-btn-group{
    margin-bottom: 24px;
  }
  .details-btn{
    width: 120px;
    margin-top: 32px;
    margin-right: 16px;
  }

  .line{
    display: flex;
    align-items: center;
    // margin-top: 88px;
    padding-top: 20px;
    border-top: 4px solid #f5f5f5;
  }
  .tags-info{
    display: flex;
  }
  .tags-info-detail{
    width: 85px;
    margin-right: 30px;
    text-align: center;
    color: #474f5e;
  }
  .tags-info-font{
    font-weight: 600;
    font-size: 18px;
  }
  // end

  // 商品详情
  .product-detail{
    margin-top: 20px;
    background: #f7f8fb;
    padding: 17px 10px;
  }
  .product-detail-title{
    text-align: left;
    padding-bottom: 9px;
    font-weight: 400;
    font-size: 16px;
    color: #4f4f4f;
  }
  .product-detail-content{
    text-align: left;
    overflow: hidden;
    border-radius: 2px;
    // max-height: 70px;
    max-height: 700px;
    overflow-y: scroll;
  }

  // 同款推荐
  .recommend{
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    color: #242833;
  }

  // TODO 与勾选标签一起,改内左边距，原:68px
  .recommend-list{
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 16px 24px 16px 24px;
    margin: 20px auto 0px auto;
    background: #f7f8fb;
    border-radius: 2px;
    overflow: hidden;
    &-check{
      position: absolute;
      left: 26px;
    }
    &-images{
      background-color: #fff;
      width: 160px;
      height: 160px;
      position: relative;
    }
    &-imagei{
      object-fit: contain;
      object-position: center;
      height: 100%;
      z-index: 100;
    }
    // 列表里的商品
    &-product{
      text-align: left;
      flex: 1 1;
      margin-left: 44px;
      &-label{
        text-align: left;
        width: 85%;
        height: 44px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 14px;
        color: #242833;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &-btn{
        display: flex;
        align-items: center;
        &-shop{
              display: inline-block;
              width: 90px;
              margin: 0 20px 0 10px;
        }
      }
      &-order{
        width: 100%;
        display: flex;
        margin-top: 50px;
        &-detail{
          width: 145px;
          display: flex;
          align-items: center;
          margin-right: 12px;
          &-label{
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            color: #474f5e;
          }
          &-value{
              margin-left: 10px;
              font-weight: 600;
              font-size: 18px;
              text-align: left;
              color: #474f5e;
          }
        }
      }
    }
  }

  .similar{
    margin: 10px;
    background-color: #fff;
  }
  .similarity{
    width: 100%;
    padding: 10px;
    cursor: pointer;
     &-images{
      // width: 100%;
      // height: 100%;
      position: relative;
    }
    &-imagei{
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      z-index: 100;
    }
    &-price{
      text-align: left;
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: 500;
      font-size: .8vw;
      color: #4f4f4f;
    }
  }

  .collect{
    border: rgb(138, 216, 142);
    background-color: rgb(138, 216, 142);
  }

  // 相似商品
  .similarity:hover{
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
  }

  // 面包屑
  .breadmenu{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    cursor: pointer;
    border-top: #eaedf1 solid 1px;
    width: 100%;
    text-align: left;
    padding: 8px;
    background: #fff;
  }
</style>
