/**
 * @title 返回随机数
 * @param 随机数的最小值
 * @param 随机数的最大值
 */
export function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10)
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
    default:
      return 0
  }
}

/** 请求头 */
import { getToken, getusernameToken } from '@/utils/cookie'
/** 图片上传限制 */
import { message } from 'ant-design-vue'

export const headers = {
  Authorization: 'Bearer ' + getToken(getusernameToken()) || ''
}

/** 文件转64 */
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

/** 文件转64callback */
export function getBaseCallback64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('只能上传JPG、PNG图片!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('图片不能超过2MB!')
  }
  return isJpgOrPng && isLt2M
}

/** 文件上传路径 */
export const upLoadObj = {
  action: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL + '/file/upload' : '/api' + '/file/upload'
}
